import React from "react"
import "../styles/index/landing-box.sass"
import index from "../styles/index/index.module.sass"
import SEO from "../components/seo"
import Layout from "../components/layout"
import cx from "classnames"
import { format } from "date-fns"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { window } from "browser-monads"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import TextTransition, { presets } from "../components/index/text-transition"
import core from "../data/core.json"

function getTimeDiff(date) {
  let diff, unit;
  const dt = Date.parse(date);
  const now = new Date();

  diff = parseInt(Math.abs(dt - now)/1000);
  unit = "second";
  let calculated = false;
  if(diff>59 && !calculated){
    diff = parseInt(diff/60);
    unit = "min";
  } else {calculated = true;}
  if(diff>59 && !calculated){
    diff = parseInt(diff/60);
    unit = "hour";
  } else {calculated = true;}
  if(diff>23 && !calculated){
    diff = parseInt(diff/24);
    unit = "day";
  } else {calculated = true;}
  if(diff>6 && !calculated){
    diff = parseInt(diff/7);
    unit = "week";
  } else {calculated = true;}
  if(diff>3 && !calculated){
    diff = parseInt(diff/4);
    unit = "month";
  } else {calculated = true;}
  if(diff>11 && !calculated){
    diff = parseInt(diff/12);
    unit = "year";
  }

  const s = diff > 1 ? "s" : "";
  return diff + " " + unit + s + " ago";
}


export const Project = ({ title, desc, path, coverImg, lastUpdated, tags }) => (
  <div className="col-12 col-md-6 pb-4">
    <Link to={path} className="no-underline" id="path">
      <div className="grow col-12 px-0 mx-auto row project-container shadow">
        <div className="col-12 m-0 p-0">
          <Img
            fluid={coverImg.childImageSharp.fluid}
            objectFit="cover"
            className="project-image-full"
            style={{ width: "100%", height: "100%", maxHeight: 220 }}
          />
        </div>
        <div className="col-12 mt-3">
          <h4 className="mb-1 mt-0"><b>{title}</b></h4>
          <h6>Updated {getTimeDiff(lastUpdated)}</h6>
          <div className="project-tag-container mb-2">
            {tags.map((tag) => (
              <div key={title+"-"+tag} className="project-tag text-nowrap my-1 mr-2">
                <p className="mb-0" >
                  {tag}
                </p>
              </div>
            ))}
          </div>
          <p className="mb-3 mt-0">{desc}</p>
        </div>
      </div>
    </Link>
  </div>
)

export const Article = ({ title, date, desc, path, coverImg, tags }) => (
  <div className="col-12 col-md-6 pb-4">
    <Link to={path} className="no-underline" id="path">
      <div className="grow col-12 px-0 mx-auto row project-container shadow">
        <div className="col-12 m-0 p-0">
          <Img
            fluid={coverImg.childImageSharp.fluid}
            objectFit="cover"
            className="project-image-full"
            style={{ width: "100%", height: "100%", maxHeight: 220 }}
          />
        </div>
        <div className="col-12 mt-3">
          <h4 className="mb-1 mt-0"><b>{title}</b></h4>
          <h6>{format(new Date(date), "iii, dd MMM yyyy")}</h6>
          <div className="project-tag-container mb-2">
            {tags.map((tag) => (
              <div key={title+"-"+tag} className="project-tag text-nowrap my-1 mr-2">
                <p className="mb-0" >
                  {tag}
                </p>
              </div>
            ))}
          </div>
          <p className="mb-3 mt-0">{desc}</p>
        </div>
      </div>
    </Link>
  </div>
)

export const Fundraiser = ({ title, date, desc, path, coverImg, tags }) => (
  <div className="col-sm-12 col-md-6 pb-4">
    <Link to={path} className="no-underline" id="path">
      <div className="grow col-12 px-0 mx-auto row project-container shadow">
        <div className="col-12 m-0 p-0">
          <Img
            fluid={coverImg.childImageSharp.fluid}
            objectFit="cover"
            className="project-image-full"
            style={{ width: "100%", height: "100%", maxHeight: 220 }}
          />
        </div>
        <div className="col-12 mt-3">
          <h4 className="mb-1 mt-0"><b>{title}</b></h4>
          <h6>{format(new Date(date), "iii, dd MMM yyyy")}</h6>
          <p className="mb-3 mt-0">{desc}</p>
        </div>
      </div>
    </Link>
  </div>
)

export class Page extends React.Component {
  state = {
    index: 0
  } 

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ index: this.state.index + 1 }), 4000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const projectEdges = this.props.data.Projects.edges // this.props.data.markdownRemark holds our post this.props.data
    const fundraiserEdges = this.props.data.Fundraisers.edges // this.props.data.markdownRemark holds our post this.props.data
    let { edges } = this.props.data.allMdx // this.props.data.markdownRemark holds our post this.props.data

    if(this.props.showLandingBox){
      return(
        <div>
          <div id="landing-box" className={this.props.showLandingBox ? "landing-container" : "nothing"}>
            {window.localStorage.getItem("visited") === "true" 
              ?
              <button
                className={"skip btn"}
                onClick={this.props.handleChange}>
                  {"Skip animation ->"}
              </button> 
              : 
              null
            }
            <div className={"middle"}>
              <div className={cx("text-nowrap", "text")}>
                <p id={"line0"}>Hello world, I'm <span style={{color: "#FB2F6D"}}>Jake Thomson</span>.</p>
                <p id={"line1"}>I'm a computer science graduate and</p>
                <div className="mb-5" id="line2">
                  <TextTransition
                    inline={true}
                    delay={1000}
                    style={{minWidth: "750px"}}
                    text={ core.titles[this.state.index % core.titles.length] }
                    springConfig={presets.molasses}
                  />
                </div>
              </div>
              <button
                className={"landing-btn"}
                onClick={this.props.handleChange}>
                Welcome to my site
              </button>
            </div>
            <style>{`body{overflow-y: hidden;}`}</style>
          </div>
        </div>
      )
    } else {
      return(
        <Layout>
          <div>
            <div className="row px-3 pt-4 pb-5">
              <div className="col-12 p-0 pb-4">
                <h2 className={cx("mt-2", index.h2)}>Latest Project Activity</h2>
              </div>
              {projectEdges.slice(0, 2).map((item) => (
                <Project 
                  {...item.node.frontmatter}
                  key={item.node.frontmatter.title}
                />
              ))}
            </div>
          </div>
          {/* <div className="is-grey">
              <div className="re-center-lg row pt-4 pb-5">
                <div className="col-12 p-0 pb-4">
                  <h2 className={cx("mt-2", index.h2)}>Recent Articles</h2>
                </div>
                {edges.slice(0, 2).map((item) => (
                  <Article
                    {...item.node.frontmatter}
                    key={item.node.frontmatter.title}
                  />
                ))}
              </div>
          </div> */}
          <div>
            <div className="row px-3 pt-4 pb-5">
              <div className="col-12 p-0 pb-4">
              <h2 className={cx("mt-2", index.h2)}>Latest Fundraisers</h2>
              </div>
              {fundraiserEdges.slice(0, 2).map((item) => (
                <Fundraiser 
                  {...item.node.frontmatter}
                  key={item.node.frontmatter.title}
                />
              ))}
            </div>
          </div>
        </Layout>
      )
    }
  }
}

export default class Index extends React.Component {
  state = {
    showLandingBox: true
  }

  handleChange = () => {
    this.setState({
      showLandingBox: false
    });
    // sessionStorage.setItem('showLandingBox', false);
    localStorage.setItem('visited', true);
  }

  render() {  
    return (
      <div className={"index"} style={this.props.showLandingBox ? {overflowX: "hidden", overflowY: "scroll"} : {overflowX: "hidden"}} >
        <SEO title="Home" />
        {window.sessionStorage.getItem("showLandingBox") !== "false" 
          ? 
          <SwitchTransition>
            <CSSTransition
              key={this.state.showLandingBox}
              timeout={1000}
              classNames={"introquit"}
              onEntered={() => sessionStorage.setItem('showLandingBox', false)}
            >
              <Page showLandingBox={this.state.showLandingBox} handleChange={this.handleChange} data={this.props.data}/>
            </CSSTransition>
          </SwitchTransition> 
          : 
          <Page showLandingBox={false} data={this.props.data} />
        }
    </div>
  )}
}

export const query = graphql`
  {
    allMdx(
      filter: {frontmatter: {public: {eq: true}, type: {eq: "Article"}}}
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            desc
            path
            tags
            coverImg {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    Projects: allMdx(
      filter: {frontmatter: {public: {eq: true}, type: {eq: "Project"}}}
      sort: { order: DESC, fields: [frontmatter___lastUpdated] }
    ) {
      edges {
        node {
          frontmatter {
            type
            title
            desc
            date
            lastUpdated
            tags
            path
            coverImg {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    Fundraisers: allMdx(
      filter: {frontmatter: {public: {eq: true}, type: {eq: "Fundraiser"}}}
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            type
            title
            desc
            date
            tags
            path
            coverImg {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

